<template>
  <div class="fragment">
    <ProductsTableAdmin
        v-if="$store.getters.getIsAdminRights === 'admin'"
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        :navTabs="navTabs"
        :sortParams="sortParams"
        @changeSort="data => changeSort(data)"
        @showMore="showMore"
        @downloadFiles="downloadFiles"
        @changeFilter="changeFilter"
        @changeTab="changeTab"
        @resetFilter="resetFilter"
        @massThermalPrint="massThermalPrint"
        @reload="reload"
        @reloadTable="filter"
        @goodsRelocation="showGoodsRelocationPopup"
    />
    <ProductsTableUser
        v-if="$store.getters.getIsAdminRights === 'user'"
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        :navTabs="navTabs"
        :sortParams="sortParams"
        @changeSort="data => changeSort(data)"
        @showMore="showMore"
        @downloadFiles="downloadFiles"
        @changeFilter="changeFilter"
        @changeTab="changeTab"
        @resetFilter="resetFilter"
        @massThermalPrint="massThermalPrint"
        @reload="reload"
        @reloadTable="filter"
    />

    <RelocateGoodsPopup
        v-if="isModalRelocateGoods"
        :storage="_.find(navTabs, {active: true})"
        @close="isModalRelocateGoods = false"
        @successRelocate="successRelocate"
    />
  </div>
</template>

<script>
  import ProductsTableUser from "./ProductsTableUser/ProductsTableUser";
  import ProductsTableAdmin from "./ProductsTableAdmin/ProductsTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import RelocateGoodsPopup from "../../../../coreComponents/Popups/RelocateGoodsPopup/RelocateGoodsPopup";

  export default {
    name: "ProductsTable",

    components: {
      RelocateGoodsPopup,
      ProductsTableAdmin,
      ProductsTableUser,
    },

    mixins: [routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getUnidentifiedPaymentsForPage,
        page: 1,
        onePage: 1,
        filterDate: [],
        filterType: '',
        filterProductName: '',
        filterUPC: '',
        filterSKU: '',
        filterId: '',
        filterNameInStore: '',
        filterUserName: '',
        filterUserId: '',

        filterInTransit: '',
        filterReserved: '',
        filterFBM: '',
        filterFBA: '',
        filterDamaged: '',
        filterShelves: '',
        filterCellName: '',

        filterArchive: '',

        sortParams: {
          sortId: '',
          sortTransit: '',
          sortReserv: '',
          sortNormal: '',
          sortDamaged: '',
        },

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {},
        isModalRelocateGoods: false,
      }
    },

    mounted() {

      this.getWarehouseStorages().then(() => {
        if(this.$route.query.type !== undefined){
          this.skipRadioChecked(this.navTabs, 'active')
          this.navTabs[this.$route.query.type].active = true
        }
        // if(!this.$route.query.sortId) {
        //   let query = Object.assign({}, this.$route.query)
        //   query.sortId = '-'
        //   this.$router.replace({ query })
        // }
        this.filter()
      })
    },


    methods: {

      getSortParam() {
        this.sortParams = {
          sortId: this.$route.query.sortId || '',
          sortTransit: this.$route.query.sortTransit || '',
          sortReserv: this.$route.query.sortReserv || '',
          sortNormal: this.$route.query.sortNormal || '',
          sortDamaged: this.$route.query.sortDamaged || '',
        }
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          type: this.$route.query.type,
          productName: this.$route.query.productName,
          UPC: this.$route.query.UPC,
          SKU: this.$route.query.SKU,
          id: this.$route.query.id,
          nameInStore: this.$route.query.nameInStore,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          inTransit: this.$route.query.inTransit,
          reserved: this.$route.query.reserved,
          FBM: this.$route.query.FBM,
          FBA: this.$route.query.FBA,
          damaged: this.$route.query.damaged,
          shelves: this.$route.query.shelves,
          cellName: this.$route.query.cellName,

          archive: this.$route.query.archive,
        }
      },


      async getWarehouseStorages() {
        return this.$store.dispatch('fetchWarehouseStorage').then((response) => {
          let warehouses = this.getRespPaginateData(response)
          let warehousesLocal = {}
          let activeTab = Number(this.$route.query?.warehouse) || 1

          warehouses.map((item) => {
            let warehouseItem = {}
            warehouseItem['id'] = item.id
            warehouseItem['name'] = item.id
            warehouseItem['label'] = item.name
            warehouseItem['warehouse_id'] = item.warehouse_id
            warehouseItem['active'] = activeTab === item.id
            warehousesLocal[item.id] = this._.clone(warehouseItem)
          })

          this.navTabs = warehousesLocal
        })
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()
        this.getSortParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextProductsPage', true)

        // save filter params in store
        this.$store.commit('setProductsFilter', window.location.search)

        this.$store.dispatch('fetchProductsWithPermissions', url).then(() => {
          // skip get next page
          this.$store.commit('setNextProductsPage', false)
        })

        this.checkCountFilter(['userName', 'type'])
      },

      generateFilterUrl(page, forPage, exportIds = false) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        myQuery.includes('itemsInStorage')

        this.generateFilterQueryParams(
          myQuery,
          {
            filterType: 'byStorageId',
            filterProductName: 'ProductName',
            filterUPC: 'ProductUpcCode',
            filterSKU: 'ProductSkuCode',
            filterId: 'ProductId',
            filterNameInStore: 'ProductNameFromShop',
            filterUserId: 'ProductUserId',

            filterInTransit: 'byQuantityInTransit',
            filterReserved: 'byQuantityInReserve',
            filterFBM: 'byQuantityInStorage',
            // filterFBA: 'ProductUserId',
            filterDamaged: 'byQuantityDamageInStorage',
            // filterShelves: 'ProductUserId',
            filterCellName: 'cell_name',

            filterArchive: 'ProductIsArchived',
          },
        )

        // If generate on export file
        if(exportIds) {
          myQuery.whereIn('ProductId', exportIds)
        }

        this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''
        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
            'between_created_at',
            date
          )
        }

        this.generateSortQueryParams(
            myQuery,
            {
              sortId: 'ProductId',
              sortTransit: 'ProductVirtualStorageItemTransit',
              sortReserv: 'ProductVirtualStorageItemReserv',
              sortNormal: 'ProductInStorageNormal',
              sortDamaged: 'ProductInStorageDamaged',
            }
        )

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },


      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        this.$store.dispatch('getExportProducts', {filter: url, exportType: type}).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            const blob = new Blob([response.data], {type: `application/${type}`})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `Products.${type}`
            link.click()
            URL.revokeObjectURL(link.href)
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

      },


     massThermalPrint() {
        let data = []
        this.getCheckedRows('row-name').map(item => {
          data.push({
            id: item
          })
        })

        this.$store.dispatch('massThermalPrint', {products: data}).then((response) => {
          let data = this.getRespData(response)
          this.globalOpenPdfBase64(data?.fileContentBase64)
        })
      },

      showGoodsRelocationPopup() {
        this.isModalRelocateGoods = true
      },

      successRelocate() {
        this.isModalRelocateGoods = false

        this.filter()
      }

    }


  }
</script>

<style scoped>

</style>
